// src/Sidebar.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  IconButton,
  Box,
  ListItemIcon,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import PeopleIcon from '@mui/icons-material/People';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ImageIcon from '@mui/icons-material/Image'; // Ícone para Gerenciar Carrossel

const Sidebar = () => {
  const [isCompact, setIsCompact] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/auth/profile/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStoreName(response.data.store_name);
        setLogoUrl(response.data.logo_url ? `https://api.velhochicoveiculos.com.br/${response.data.logo_url}` : '');
      } catch (error) {
        console.error('Erro ao buscar dados da loja', error);
      }
    };

    fetchStoreData();
  }, []);

  const handleToggleSidebar = () => {
    setIsCompact(!isCompact);
  };

  const menuItems = [
    { text: 'Listagem de Veículos', icon: <DirectionsCarIcon />, path: '/vehicles' },
    { text: 'Adicionar Veículo', icon: <AddCircleIcon />, path: '/add-vehicle' },
    { text: 'Fotos dos Veículos', icon: <PhotoLibraryIcon />, path: '/vehicle-photo-list' },
    { text: 'Meu Perfil', icon: <AccountCircleIcon />, path: '/profile' },
    { text: 'Leads', icon: <GroupIcon />, path: '/leads' },
    { text: 'Membros do Sistema', icon: <PeopleIcon />, path: '/members' },
    { text: 'Pedidos de Financiamento', icon: <MonetizationOnIcon />, path: '/finance-requests' },
    { text: 'Solicitações de Venda/Troca', icon: <SwapHorizIcon />, path: '/sell-or-trade-requests' },
    { text: 'Gerenciar Carrossel', icon: <ImageIcon />, path: '/carousel-manager' }, // Novo item para Gerenciar Carrossel
  ];

  return (
    <>
      <IconButton
        onClick={handleToggleSidebar}
        sx={{
          position: 'absolute',
          top: 16,
          left: isCompact ? 80 : 250,
          zIndex: 1300,
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '50%',
          boxShadow: 1,
          width: '40px',
          height: '40px',
        }}
      >
        {isCompact ? <ArrowForwardIosIcon fontSize="small" /> : <ArrowBackIosIcon fontSize="small" />}
      </IconButton>

      <Drawer
        anchor="left"
        open
        variant="permanent"
        sx={{
          width: isCompact ? 80 : 250,
          transition: 'width 0.3s',
          '& .MuiDrawer-paper': { width: isCompact ? 80 : 250, overflowX: 'hidden' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <Box p={2} textAlign="center">
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="Logo da Loja"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  marginBottom: 16,
                  display: isCompact ? 'none' : 'block',
                }}
              />
            ) : (
              <Typography variant="h6">{storeName || 'Minha Loja'}</Typography>
            )}
          </Box>

          <List>
            {menuItems.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  component={Link}
                  to={item.path}
                  sx={{
                    flexDirection: isCompact ? 'column' : 'row',
                    alignItems: 'center',
                    justifyContent: isCompact ? 'center' : 'flex-start',
                    textAlign: 'center',
                    gap: isCompact ? 0 : 1,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, color: 'inherit', justifyContent: 'center' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      textAlign: isCompact ? 'center' : 'left',
                      fontSize: isCompact ? '0.8rem' : '1rem',
                      display: isCompact ? 'block' : 'inline',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
