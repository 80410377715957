import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  Box,
  FormControl,
  InputLabel,
} from '@mui/material';

const LeadEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState({
    nome: '',
    telefone: '',
    classificacao: '',
    origem: 'Instagram',
    nota: '',
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLead = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token de autenticação não encontrado.');
        return;
      }

      try {
        const response = await axios.get(`https://api.velhochicoveiculos.com.br/api/leads/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLead(response.data);
      } catch (error) {
        console.error('Erro ao carregar lead:', error);
        setError('Erro ao carregar lead');
      }
    };

    fetchLead();
  }, [id]);

  const handleChange = (e) => {
    setLead({ ...lead, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.put(`https://api.velhochicoveiculos.com.br//api/leads/update/${id}`, lead, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Lead atualizado com sucesso!');
      navigate('/leads');
    } catch (error) {
      console.error('Erro ao atualizar lead:', error);
      setError('Erro ao atualizar lead');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Editar Lead
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Nome"
          name="nome"
          fullWidth
          value={lead.nome}
          onChange={handleChange}
          required
          margin="normal"
        />
        <TextField
          label="Telefone"
          name="telefone"
          fullWidth
          value={lead.telefone}
          onChange={handleChange}
          required
          margin="normal"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Classificação</InputLabel>
          <Select
            name="classificacao"
            value={lead.classificacao}
            onChange={handleChange}
            required
          >
            <MenuItem value="">
              <em>Selecione uma classificação</em>
            </MenuItem>
            <MenuItem value="Lead Quente">Lead Quente</MenuItem>
            <MenuItem value="Lead Frio">Lead Frio</MenuItem>
            <MenuItem value="Cliente Interessado">Cliente Interessado</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Origem</InputLabel>
          <Select
            name="origem"
            value={lead.origem}
            onChange={handleChange}
            required
          >
            <MenuItem value="Instagram">Instagram</MenuItem>
            <MenuItem value="Facebook">Facebook</MenuItem>
            <MenuItem value="Olx">OLX</MenuItem>
            <MenuItem value="Webmotors">Webmotors</MenuItem>
            <MenuItem value="Presencial">Presencial</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Nota"
          name="nota"
          fullWidth
          value={lead.nota}
          onChange={handleChange}
          multiline
          rows={4}
          margin="normal"
        />

        <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
          Salvar Alterações
        </Button>
      </Box>
      {error && (
        <Typography color="error" align="center" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default LeadEdit;
