import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  Tab,
  Tabs,
  TextField,
  Button,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [storeData, setStoreData] = useState({
    nome_loja: '',
    endereco: '',
    logo_url: '',
  });
  const [logoFile, setLogoFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const profileResponse = await axios.get('https://api.velhochicoveiculos.com.br/api/auth/profile/me', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setProfile({
          username: profileResponse.data.username || '',
          email: profileResponse.data.email || '',
          password: '',
        });

        const storeResponse = await axios.get('https://api.velhochicoveiculos.com.br/api/auth/profile/store', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setStoreData({
          nome_loja: storeResponse.data.nome_loja || '',
          endereco: storeResponse.data.endereco || '',
          logo_url: storeResponse.data.logo_url || '',
        });

        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar perfil:', error?.response?.data || error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePersonalChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleStoreChange = (e) => {
    setStoreData({ ...storeData, [e.target.name]: e.target.value });
  };

  const handleLogoChange = (e) => {
    setLogoFile(e.target.files[0]);
  };

  const handlePersonalSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const updatedProfile = Object.fromEntries(
      Object.entries(profile).filter(([, value]) => value !== '')
    );

    try {
      await axios.put('https://api.velhochicoveiculos.com.br/api/auth/profile/me', updatedProfile, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Dados pessoais atualizados com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar dados pessoais:', error?.response?.data || error.message);
      alert('Erro ao atualizar dados pessoais');
    }
  };

  const handleStoreSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();
    if (logoFile) formData.append('logo', logoFile);

    Object.entries(storeData).forEach(([key, value]) => {
      if (value !== '') formData.append(key, value);
    });

    try {
      await axios.put('https://api.velhochicoveiculos.com.br/api/auth/profile/store', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Dados da loja atualizados com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar dados da loja:', error?.response?.data || error.message);
      alert('Erro ao atualizar dados da loja');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Meu Perfil
      </Typography>

      <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ mb: 2 }}>
        <Tab label="Dados Pessoais" />
        <Tab label="Dados da Loja" />
      </Tabs>

      {activeTab === 0 && (
        <Card>
          <CardContent>
            <Typography variant="h6">Dados Pessoais</Typography>
            <Box component="form" onSubmit={handlePersonalSubmit} mt={2}>
              <TextField
                fullWidth
                margin="normal"
                label="Nome de Usuário"
                name="username"
                value={profile.username}
                onChange={handlePersonalChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                type="email"
                value={profile.email}
                onChange={handlePersonalChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Senha"
                name="password"
                type="password"
                placeholder="Deixe em branco para não alterar"
                value={profile.password}
                onChange={handlePersonalChange}
              />
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                Salvar Dados Pessoais
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}

      {activeTab === 1 && (
        <Card>
          <CardContent>
            <Typography variant="h6">Dados da Loja</Typography>
            <Box component="form" onSubmit={handleStoreSubmit} mt={2}>
              <TextField
                fullWidth
                margin="normal"
                label="Nome da Loja"
                name="nome_loja"
                value={storeData.nome_loja}
                onChange={handleStoreChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Endereço da Loja"
                name="endereco"
                value={storeData.endereco}
                onChange={handleStoreChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="URL do Logo"
                name="logo_url"
                value={storeData.logo_url}
                onChange={handleStoreChange}
              />
              <Box mt={2}>
                <input type="file" accept=".png, .jpeg, .jpg, .svg" onChange={handleLogoChange} />
              </Box>
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                Salvar Dados da Loja
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default Profile;
