import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Badge,
  Box,
} from '@mui/material';
import { Edit, Delete, Info, Add } from '@mui/icons-material';

const Vehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const [patioValue, setPatioValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(['Estoque', 'Negociação']);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/vehicles', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setVehicles(response.data);
      } catch (error) {
        console.error('Erro ao buscar veículos', error);
      }
    };

    const fetchPatioValue = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/vehicles/patio-value', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPatioValue(response.data.patioValue);
      } catch (error) {
        console.error('Erro ao buscar Valor do Pátio:', error);
      }
    };

    fetchVehicles();
    fetchPatioValue();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Tem certeza que deseja excluir este veículo?");
    if (confirmDelete) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`https://api.velhochicoveiculos.com.br/api/vehicles/delete/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        alert('Veículo excluído com sucesso!');
        setVehicles(vehicles.filter((vehicle) => vehicle.id !== id));
      } catch (error) {
        console.error('Erro ao excluir veículo:', error);
        alert('Erro ao excluir veículo.');
      }
    }
  };

  const updateStatusFilter = (status) => {
    setStatusFilter((prev) =>
      prev.includes(status) ? prev.filter((s) => s !== status) : [...prev, status]
    );
  };

  const filteredVehicles = vehicles.filter(
    (vehicle) =>
      (vehicle.modelo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vehicle.fabricante.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vehicle.placa.toLowerCase().includes(searchTerm.toLowerCase())) &&
      statusFilter.includes(vehicle.status)
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Listagem de Veículos
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        Valor do Pátio: R$ {patioValue.toLocaleString('pt-BR')}
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        label="Buscar por modelo, fabricante ou placa"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ my: 3 }}
      />

      <Box display="flex" justifyContent="center" mb={3}>
        {['Estoque', 'Negociação', 'Vendido', 'Chegando'].map((status) => (
          <Chip
            key={status}
            label={status}
            onClick={() => updateStatusFilter(status)}
            color={statusFilter.includes(status) ? 'primary' : 'default'}
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>

      <List>
        {filteredVehicles.length > 0 ? (
          filteredVehicles.map((vehicle) => (
            <ListItem key={vehicle.id} divider>
              <ListItemText
                primary={`${vehicle.modelo} - ${vehicle.fabricante} (${vehicle.placa})`}
                secondary={
                  <>
                    <Typography component="span" variant="body2">
                      Valor Compra: <strong>R${vehicle.valor_compra}</strong> <br />
                      Valor Pedido: <strong>R${vehicle.valor_pedido}</strong> <br />
                    </Typography>
                    <Badge color="secondary" badgeContent={vehicle.status} sx={{ mt: 1 }}>
                      Status
                    </Badge>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" color="info" component={Link} to={`/vehicle-details/${vehicle.id}`}>
                  <Info />
                </IconButton>
                <IconButton edge="end" color="warning" component={Link} to={`/edit-vehicle/${vehicle.id}`}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" color="error" onClick={() => handleDelete(vehicle.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        ) : (
          <Typography align="center" variant="subtitle1" sx={{ mt: 2 }}>
            Nenhum veículo encontrado.
          </Typography>
        )}
      </List>

      <Box textAlign="center" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="success"
          component={Link}
          to="/add-vehicle"
          startIcon={<Add />}
        >
          Adicionar Veículo
        </Button>
      </Box>
    </Container>
  );
};

export default Vehicles;
