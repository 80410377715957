// src/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';

const Header = ({ theme, toggleTheme, handleLogout }) => {
  return (
    <AppBar position="static" color="default" className="header-controls">
      <Toolbar>
        <Link to="/profile" className="profile-link" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button color="inherit">Editar Perfil</Button>
        </Link>
        <Button color="inherit" onClick={handleLogout}>
          Sair
        </Button>
        <IconButton edge="end" color="inherit" onClick={toggleTheme}>
          {theme === 'light' ? <Brightness4 /> : <Brightness7 />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
