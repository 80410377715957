import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Divider,
} from '@mui/material';

const VehiclePhotoList = () => {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    // Função para buscar todos os veículos
    const fetchVehicles = async () => {
      try {
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/vehicles', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setVehicles(response.data);
      } catch (error) {
        console.error('Erro ao buscar veículos:', error);
      }
    };

    fetchVehicles();
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Lista de Veículos - Fotos
      </Typography>
      <List>
        {vehicles.map((vehicle) => (
          <React.Fragment key={vehicle.id}>
            <ListItem
              secondaryAction={
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/vehicle-photos/${vehicle.id}`}
                >
                  Ver Fotos
                </Button>
              }
            >
              <ListItemText
                primary={`${vehicle.fabricante} ${vehicle.modelo} (${vehicle.anoFabricacao}/${vehicle.anoModelo})`}
                secondary={`Quilometragem: ${vehicle.quilometragem} km`}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default VehiclePhotoList;
