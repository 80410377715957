import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Link as MuiLink,
  Box,
} from '@mui/material';

const VehicleDetails = () => {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVehicle = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token de autenticação não encontrado.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://api.velhochicoveiculos.com.br/api/vehicles/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data) {
          setVehicle(response.data);
        } else {
          setError('Nenhum dado encontrado para o veículo.');
        }
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar veículo:', error.response ? error.response.data : error.message);
        setError('Erro ao carregar os dados do veículo');
        setLoading(false);
      }
    };

    fetchVehicle();
  }, [id]);

  const calculateTotalExpenses = () => {
    return (
      (parseFloat(vehicle.despesas_transferencia) || 0) +
      (parseFloat(vehicle.comissao) || 0) +
      (parseFloat(vehicle.ipva) || 0) +
      (parseFloat(vehicle.frete) || 0)
    );
  };

  const handlePrint = () => {
    window.print();
  };

  if (loading) return <Typography>Carregando...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Detalhes do Veículo
      </Typography>
      {vehicle && (
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Placa:</strong> {vehicle.placa}</Typography>
                <Typography variant="body1"><strong>Fabricante:</strong> {vehicle.fabricante}</Typography>
                <Typography variant="body1"><strong>Modelo:</strong> {vehicle.modelo}</Typography>
                <Typography variant="body1"><strong>Cor:</strong> {vehicle.cor}</Typography>
                <Typography variant="body1"><strong>Ano de Fabricação:</strong> {vehicle.ano_fab}</Typography>
                <Typography variant="body1"><strong>Ano do Modelo:</strong> {vehicle.ano_mod}</Typography>
                <Typography variant="body1"><strong>Quilometragem:</strong> {vehicle.quilometragem}</Typography>
                <Typography variant="body1"><strong>Origem da Compra:</strong> {vehicle.origem_compra}</Typography>
                <Typography variant="body1"><strong>Comprador:</strong> {vehicle.comprador}</Typography>
                <Typography variant="body1"><strong>Número do Comprador:</strong> {vehicle.numero_comprador}</Typography>
                <Typography variant="body1"><strong>Data da Compra:</strong> {vehicle.data_compra}</Typography>
                <Typography variant="body1"><strong>Valor Pedido:</strong> R${vehicle.valor_pedido}</Typography>
                <Typography variant="body1"><strong>Valor de Compra:</strong> R${vehicle.valor_compra}</Typography>
                <Typography variant="body1"><strong>FIPE:</strong> R${vehicle.fipe}</Typography>
                <Typography variant="body1"><strong>Margem FIPE Compra:</strong> {vehicle.margem_fipe_compra}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Despesas</Typography>
                <Typography variant="body1"><strong>Despesas de Transferência:</strong> R$ {vehicle.despesas_transferencia || 0}</Typography>
                <Typography variant="body1"><strong>Comissão:</strong> R$ {vehicle.comissao || 0}</Typography>
                <Typography variant="body1"><strong>IPVA:</strong> R$ {vehicle.ipva || 0}</Typography>
                <Typography variant="body1"><strong>Frete:</strong> R$ {vehicle.frete || 0}</Typography>
                <Typography variant="body1"><strong>Total de Despesas:</strong> R$ {calculateTotalExpenses()}</Typography>
              </Grid>
            </Grid>

            <Box mt={4}>
              <Typography variant="h6">Documentos</Typography>
              {vehicle.pdf_crlv ? (
                <MuiLink href={`https://api.velhochicoveiculos.com.br/uploads/${vehicle.pdf_crlv}`} target="_blank" rel="noopener noreferrer">
                  Download CRLV
                </MuiLink>
              ) : (
                <Typography color="textSecondary">CRLV não disponível</Typography>
              )}
              {vehicle.pdf_dut ? (
                <MuiLink href={`https://api.velhochicoveiculos.com.br/uploads/${vehicle.pdf_dut}`} target="_blank" rel="noopener noreferrer" sx={{ ml: 2 }}>
                  Download DUT
                </MuiLink>
              ) : (
                <Typography color="textSecondary" sx={{ ml: 2 }}>DUT não disponível</Typography>
              )}
            </Box>

            <Button onClick={handlePrint} variant="contained" color="primary" sx={{ mt: 4 }}>
              Imprimir Ficha
            </Button>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default VehicleDetails;
