import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Box,
  Card,
  Typography,
  TextField,
  Button,
  Alert
} from '@mui/material';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.velhochicoveiculos.com.br/api/auth/login', {
        username,
        password,
      });
      
      // Armazenar o token JWT no localStorage
      localStorage.setItem('token', response.data.token);
      
      // Redirecionar para a página inicial
      window.location.href = '/home';

    } catch (error) {
      setErrorMessage('Login falhou. Verifique seu nome de usuário e senha.');
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Card sx={{ p: 4, width: '100%' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            label="Usuário"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2, textAlign: 'center' }}>
              {errorMessage}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Entrar
          </Button>
        </Box>
      </Card>
    </Container>
  );
};

export default Login;
