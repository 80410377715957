// src/CarouselManager.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CarouselManager = () => {
  const [images, setImages] = useState([]);
  const [newImageUrl, setNewImageUrl] = useState('');
  const [editImageUrl, setEditImageUrl] = useState('');
  const [editMode, setEditMode] = useState(null); // ID da imagem em modo de edição
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCarouselImages();
  }, []);

  // Função para buscar as imagens do carrossel
  const fetchCarouselImages = async () => {
    try {
      const response = await axios.get('https://api.velhochicoveiculos.com.br/api/public/carousel-images');
      setImages(response.data); // Certifique-se de que o backend retorna um array de URLs de imagem
      setError('');
    } catch (error) {
      console.error('Erro ao buscar imagens do carrossel:', error);
      setError('Erro ao buscar imagens do carrossel');
    }
  };

  // Função para adicionar uma nova imagem
  const handleAddImage = async () => {
    if (!newImageUrl) {
      setError('Por favor, insira uma URL de imagem.');
      return;
    }
    try {
      await axios.post('https://api.velhochicoveiculos.com.br/api/public/carousel-images', { url: newImageUrl });
      setNewImageUrl('');
      fetchCarouselImages();
    } catch (error) {
      console.error('Erro ao adicionar imagem ao carrossel:', error);
      setError('Erro ao adicionar imagem ao carrossel');
    }
  };

  // Função para excluir uma imagem
  const handleDeleteImage = async (id) => {
    try {
      await axios.delete(`https://api.velhochicoveiculos.com.br/api/admin/carousel-images/${id}`);
      fetchCarouselImages(); // Atualiza a lista após exclusão
    } catch (error) {
      console.error('Erro ao excluir imagem:', error);
      setError('Erro ao excluir imagem');
    }
  };

  // Função para ativar o modo de edição de imagem
  const handleEditImage = (id, url) => {
    setEditMode(id);
    setEditImageUrl(url);
  };

  // Função para atualizar a URL da imagem
  const handleUpdateImage = async (id) => {
    if (!editImageUrl) {
      setError('Por favor, insira uma nova URL de imagem.');
      return;
    }
    try {
      await axios.put(`https://api.velhochicoveiculos.com.br/api/admin/carousel-images/${id}`, { url: editImageUrl });
      fetchCarouselImages(); // Atualiza a lista após edição
      setEditMode(null);
      setEditImageUrl('');
    } catch (error) {
      console.error('Erro ao atualizar imagem:', error);
      setError('Erro ao atualizar imagem');
    }
  };

  return (
    <div>
      <h2>Gerenciar Imagens do Carrossel</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      <div>
        <input
          type="text"
          placeholder="URL da nova imagem"
          value={newImageUrl}
          onChange={(e) => setNewImageUrl(e.target.value)}
        />
        <button onClick={handleAddImage}>Adicionar Imagem</button>
      </div>
      
      <div>
        <h3>Imagens do Carrossel</h3>
        <ul>
          {images.map((image, index) => (
            <li key={index} style={{ marginBottom: '10px' }}>
              {editMode === index ? (
                <div>
                  <input
                    type="text"
                    value={editImageUrl}
                    onChange={(e) => setEditImageUrl(e.target.value)}
                    placeholder="Nova URL da imagem"
                  />
                  <button onClick={() => handleUpdateImage(index)}>Salvar</button>
                  <button onClick={() => setEditMode(null)}>Cancelar</button>
                </div>
              ) : (
                <div>
                  <img src={image} alt={`Carousel ${index + 1}`} style={{ width: '200px', height: '100px' }} />
                  <button onClick={() => handleEditImage(index, image)}>Editar</button>
                  <button onClick={() => handleDeleteImage(index)}>Excluir</button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CarouselManager;
