// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Login from './Login';
import Home from './Home';
import Vehicles from './Vehicles';
import AddVehicle from './AddVehicle';
import EditVehicle from './EditVehicle';
import VehicleDetails from './VehicleDetails';
import VehiclePhotos from './VehiclePhotos';
import VehiclePhotoList from './VehiclePhotoList';
import Profile from './Profile';
import LeadForm from './LeadForm';
import LeadList from './LeadList';
import LeadEdit from './LeadEdit';
import MembersList from './MembersList';
import FinanceRequests from './FinanceRequests';
import SellOrTradeRequests from './SellOrTradeRequests';
import CarouselManager from './CarouselManager'; // Importa o CarouselManager
import ProtectedRoute from './ProtectedRoute';
import { CssBaseline } from '@mui/material';
import './App.css';
import './Sidebar.css';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const token = localStorage.getItem('token');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <Router>
      <CssBaseline />
      <div className={`App ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'} ${theme}`}>
        {token && <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}

        {token && (
          <Header
            theme={theme}
            toggleTheme={toggleTheme}
            handleLogout={handleLogout}
          />
        )}

        <div className="content container mt-4">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/home"
              element={
                token ? (
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/vehicles"
              element={
                token ? (
                  <ProtectedRoute>
                    <Vehicles />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/add-vehicle"
              element={
                token ? (
                  <ProtectedRoute>
                    <AddVehicle />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/edit-vehicle/:id"
              element={
                token ? (
                  <ProtectedRoute>
                    <EditVehicle />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/vehicle-details/:id"
              element={
                token ? (
                  <ProtectedRoute>
                    <VehicleDetails />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/vehicle-photos/:id"
              element={
                token ? (
                  <ProtectedRoute>
                    <VehiclePhotos />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/vehicle-photo-list"
              element={
                token ? (
                  <ProtectedRoute>
                    <VehiclePhotoList />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/profile"
              element={
                token ? (
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/leads"
              element={
                token ? (
                  <ProtectedRoute>
                    <LeadList />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/leads/add"
              element={
                token ? (
                  <ProtectedRoute>
                    <LeadForm />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/leads/edit/:id"
              element={
                token ? (
                  <ProtectedRoute>
                    <LeadEdit />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/members"
              element={
                token ? (
                  <ProtectedRoute>
                    <MembersList />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/finance-requests"
              element={
                token ? (
                  <ProtectedRoute>
                    <FinanceRequests />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/sell-or-trade-requests"
              element={
                token ? (
                  <ProtectedRoute>
                    <SellOrTradeRequests />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/carousel-manager"
              element={
                token ? (
                  <ProtectedRoute>
                    <CarouselManager />
                  </ProtectedRoute>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/" element={<Navigate to={token ? "/home" : "/login"} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
