import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Box,
  Divider,
} from '@mui/material';
import { Add, Edit, Delete, ExpandMore, ExpandLess } from '@mui/icons-material';

const LeadList = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeads = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/leads', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLeads(response.data);
      } catch (error) {
        console.error('Erro ao buscar leads:', error);
        alert('Erro ao buscar leads');
      }
    };
    fetchLeads();
  }, []);

  const toggleNote = (leadId) => {
    setSelectedLeadId(selectedLeadId === leadId ? null : leadId);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Tem certeza que deseja excluir este lead?');
    if (confirmDelete) {
      const token = localStorage.getItem('token');
      try {
        await axios.delete(`https://api.velhochicoveiculos.com.br/api/leads/delete/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        alert('Lead excluído com sucesso!');
        setLeads(leads.filter((lead) => lead.id !== id));
      } catch (error) {
        console.error('Erro ao excluir lead:', error);
        alert('Erro ao excluir lead');
      }
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Lista de Leads
      </Typography>
      <Box textAlign="center" sx={{ mb: 3 }}>
        <Button
          component={Link}
          to="/leads/add"
          variant="contained"
          color="primary"
          startIcon={<Add />}
        >
          Adicionar Lead
        </Button>
      </Box>
      <List>
        {leads.length > 0 ? (
          leads.map((lead) => (
            <Box key={lead.id} mb={2}>
              <ListItem divider>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="subtitle1">
                        <strong>Nome:</strong> {lead.nome}
                      </Typography>
                      <Typography variant="subtitle1">
                        <strong>Telefone:</strong> {lead.telefone}
                      </Typography>
                      <Typography variant="subtitle1">
                        <strong>Classificação:</strong> {lead.classificacao}
                      </Typography>
                      <Typography variant="subtitle1">
                        <strong>Origem:</strong> {lead.origem}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => toggleNote(lead.id)}
                  >
                    {selectedLeadId === lead.id ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                  <IconButton
                    edge="end"
                    color="info"
                    onClick={() => navigate(`/leads/edit/${lead.id}`)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    edge="end"
                    color="error"
                    onClick={() => handleDelete(lead.id)}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={selectedLeadId === lead.id} timeout="auto" unmountOnExit>
                <Box p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: 1 }}>
                  <Typography variant="h6">Nota</Typography>
                  <Typography>{lead.nota || 'Sem nota disponível.'}</Typography>
                </Box>
              </Collapse>
            </Box>
          ))
        ) : (
          <Typography align="center" variant="subtitle1">
            Nenhum lead encontrado.
          </Typography>
        )}
      </List>
    </Container>
  );
};

export default LeadList;
