// src/pages/VehiclePhotos.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import { Download as DownloadIcon, FileUpload as FileUploadIcon, ContentCopy as CopyIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

const VehiclePhotos = () => {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState({});
  const [photos, setPhotos] = useState([]);
  const [bio, setBio] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const token = localStorage.getItem('token');

  const fetchPhotos = async () => {
    try {
      const response = await axios.get(`https://api.velhochicoveiculos.com.br/api/vehicles/${id}/photos`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPhotos(response.data);
    } catch (error) {
      console.error('Erro ao carregar fotos:', error);
    }
  };

  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        const response = await axios.get(`https://api.velhochicoveiculos.com.br/api/vehicles/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
        const vehicleData = response.data;
        setVehicle(vehicleData);

        const generatedBio = `
          ${vehicleData.fabricante} ${vehicleData.modelo}
          Ano/Mod: ${vehicleData.anoFabricacao}/${vehicleData.anoModelo}
          Completo

          Km: ${vehicleData.quilometragem}

          ———————————————

          ✅ Direção hidráulica

          IPVA E LICENCIAMENTO GRÁTIS.

          🔘 Financiamento em até 60x *
          🔘 Taxas mensais a partir de 1.20% *
          🔘 Até 45 dias para começar a pagar *
          *Consulte condições.
        `;
        setBio(generatedBio);
      } catch (error) {
        console.error('Erro ao carregar dados do veículo:', error);
      }
    };

    fetchVehicleData();
    fetchPhotos();
  }, [id, token]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + photos.length > 7) {
      alert('Você só pode adicionar até 7 fotos.');
      return;
    }
    setSelectedFiles(files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => formData.append('photos', file));
    try {
      await axios.post(`https://api.velhochicoveiculos.com.br/api/vehicles/${id}/photos`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Fotos enviadas com sucesso!');
      setSelectedFiles([]);
      fetchPhotos();
    } catch (error) {
      console.error('Erro ao enviar fotos:', error);
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      await axios.delete(`https://api.velhochicoveiculos.com.br/api/vehicles/${id}/photos/${photoId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Foto excluída com sucesso!');
      fetchPhotos();
    } catch (error) {
      console.error('Erro ao excluir foto:', error);
    }
  };

  const handleReplacePhoto = async (e, photoId) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('photo', file);

    try {
      await axios.put(`https://api.velhochicoveiculos.com.br/api/vehicles/${id}/photos/${photoId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Foto alterada com sucesso!');
      fetchPhotos();
    } catch (error) {
      console.error('Erro ao alterar foto:', error);
    }
  };

  const handleDownloadZip = async () => {
    try {
      const response = await axios.get(`https://api.velhochicoveiculos.com.br/api/vehicles/${id}/photos/download`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `fotos_veiculo_${id}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Erro ao baixar o arquivo zip:', error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(bio);
    alert('Biografia copiada para a área de transferência!');
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Fotos do Veículo
      </Typography>
      <Box sx={{ mt: 2, mb: 3 }}>
        <Typography>Upload de Fotos (até 7 fotos):</Typography>
        <Box display="flex" gap={2} alignItems="center" mt={1}>
          <Button
            variant="contained"
            component="label"
            startIcon={<FileUploadIcon />}
          >
            Escolher Fotos
            <input
              type="file"
              hidden
              multiple
              onChange={handleFileChange}
              accept="image/*"
            />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={selectedFiles.length === 0}
          >
            Enviar Fotos
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleDownloadZip}
            startIcon={<DownloadIcon />}
          >
            Baixar Todas as Fotos (.zip)
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {photos.map((photo, index) => (
          <Grid item xs={6} sm={4} md={3} key={photo.id}>
            <Box
              component="img"
              src={`https://api.velhochicoveiculos.com.br/${photo.path}`} 
              alt={`Foto ${index + 1}`}
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 1,
                boxShadow: 1,
              }}
            />
            <Box display="flex" justifyContent="center" gap={1} mt={1}>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleDeletePhoto(photo.id)}
              >
                Excluir
              </Button>
              <Button
                variant="outlined"
                component="label"
                color="primary"
                startIcon={<EditIcon />}
              >
                Alterar
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleReplacePhoto(e, photo.id)}
                  accept="image/*"
                />
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5">Biografia do Veículo</Typography>
        <TextField
          multiline
          fullWidth
          minRows={6}
          variant="outlined"
          value={bio}
          InputProps={{
            readOnly: true,
          }}
          sx={{ my: 2 }}
        />
        <Button
          variant="outlined"
          onClick={copyToClipboard}
          startIcon={<CopyIcon />}
        >
          Copiar Biografia
        </Button>
      </Box>
    </Container>
  );
};

export default VehiclePhotos;
