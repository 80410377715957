// src/Home.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Typography, Box } from '@mui/material';

const Home = () => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchUserName = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/auth/username', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsername(response.data.username);
      } catch (error) {
        console.error('Erro ao buscar nome do usuário:', error);
      }
    };

    fetchUserName();
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Box
        sx={{
          backgroundColor: 'black',
          color: 'white',
          padding: '30px',
          borderRadius: '12px',
          textAlign: 'center',
          maxWidth: { xs: '90%', sm: '70%', md: '50%' }, // Ajuste responsivo para largura da Box
        }}
      >
        <Typography variant="h4" gutterBottom>
          Bem-vindo novamente 👋
        </Typography>
        <Typography variant="h5" mb={2}>
          {username}
        </Typography>
        <Typography variant="body2" color="grey.400" mb={2}>
          Bem-vindo(a) de volta ao sistema de gerenciamento de veículos e leads.
        </Typography>
        <Typography variant="body2" color="grey.400" mb={3}>
          If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.
        </Typography>
        <Box display="flex" justifyContent="center">
          <img
            src="https://assets.minimals.cc/public/assets/illustrations/characters/character-3.webp"
            alt="Character illustration"
            style={{ width: '80px', borderRadius: '50%' }} // Imagem menor e arredondada
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default Home;
