// src/FinanceRequests.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

const FinanceRequests = () => {
  const [financeRequests, setFinanceRequests] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFinanceRequests = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/finance/requests', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFinanceRequests(response.data);
      } catch (err) {
        console.error("Erro ao carregar solicitações de financiamento:", err);
        setError("Erro ao carregar solicitações de financiamento");
      }
    };

    fetchFinanceRequests();
  }, []);

  const toggleReadStatus = async (id, currentStatus) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `https://api.velhochicoveiculos.com.br/api/finance/requests/${id}/read`,
        { isRead: !currentStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setFinanceRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === id ? { ...request, is_read: !currentStatus } : request
        )
      );
    } catch (err) {
      console.error('Erro ao atualizar o status de leitura:', err);
      setError('Erro ao atualizar o status de leitura');
    }
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Pedidos de Financiamento
        </Typography>
        <List>
          {financeRequests.map((request) => (
            <ListItem key={request.id} divider>
              <ListItemText
                primary={`Nome: ${request.name} | Veículo: ${request.vehicle_model}`}
                secondary={`CPF: ${request.cpf} | Entrada: R$ ${request.down_payment}, Parcelas: ${request.installments}, Contato: ${request.whatsapp}, Data: ${request.created_at}`}
              />
              <IconButton onClick={() => toggleReadStatus(request.id, request.is_read)}>
                {request.is_read ? (
                  <CheckCircle color="primary" />
                ) : (
                  <RadioButtonUnchecked />
                )}
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default FinanceRequests;
