// src/SellOrTradeRequests.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Box, List, ListItem, ListItemText, Grid, Paper } from '@mui/material';

const SellOrTradeRequests = () => {
  const [requests, setRequests] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/admin/sell-or-trade-requests', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setRequests(response.data);
      } catch (err) {
        console.error("Erro ao carregar solicitações de venda/troca:", err);
        setError("Erro ao carregar solicitações de venda/troca");
      }
    };

    fetchRequests();
  }, []);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Solicitações de Venda/Troca
        </Typography>
        <List>
          {requests.map((request) => (
            <ListItem key={request.id} divider>
              <ListItemText
                primary={`Nome: ${request.name} | CPF: ${request.cpf}`}
                secondary={
                  <Box>
                    <Typography variant="body2">
                      Intenções: {Array.isArray(request.intentions) ? request.intentions.join(', ') : JSON.parse(request.intentions).join(', ')}
                    </Typography>
                    <Typography variant="body2">Preço Pedido: R$ {request.requested_price || 'N/A'}</Typography>
                    <Typography variant="body2">Carro de Leilão: {request.auction ? 'Sim' : 'Não'}</Typography>
                    <Typography variant="body2">Possui Multas: {request.has_fines ? 'Sim' : 'Não'}</Typography>
                    <Typography variant="body2">Data: {new Date(request.created_at).toLocaleDateString()}</Typography>
                    <Box mt={2}>
                      <Typography variant="body2">Fotos:</Typography>
                      <Grid container spacing={2}>
                        {request.photos && request.photos.length > 0 ? (
                          request.photos.map((photo, index) => (
                            <Grid item xs={4} sm={3} md={2} key={index}>
                              <Paper elevation={2}>
                                <img
                                  src={photo} // Agora usa a URL completa do banco de dados
                                  alt={`Foto ${index + 1}`}
                                  style={{ width: '100%', height: 'auto', borderRadius: '4px' }}
                                />
                              </Paper>
                            </Grid>
                          ))
                        ) : (
                          <Typography variant="body2">Nenhuma foto enviada</Typography>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default SellOrTradeRequests;
