import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditVehicle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const [vehicle, setVehicle] = useState({
    placa: '',
    fabricante: '',
    modelo: '',
    cor: '',
    ano_fab: '',
    ano_mod: '',
    quilometragem: '',
    origem_compra: '',
    comprador: '',
    numero_comprador: '',
    data_compra: '',
    data_venda: '',
    valor_pedido: '',
    valor_compra: '',
    fipe: '',
    despesas_transferencia: '',
    comissao: '',
    ipva: '',
    frete: '',
    status: 'Estoque',
    valor_venda: '',
    dias_no_estoque: '',
    margem_fipe: '',
  });

  const [crlvFile, setCrlvFile] = useState(null);
  const [dutFile, setDutFile] = useState(null);

  useEffect(() => {
    const fetchVehicle = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`https://api.velhochicoveiculos.com.br/api/vehicles/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data) {
          const data = response.data;
          data.data_compra = data.data_compra ? new Date(data.data_compra).toISOString().split('T')[0] : '';
          data.data_venda = data.data_venda ? new Date(data.data_venda).toISOString().split('T')[0] : '';
          setVehicle({
            ...data,
            margem_fipe: (parseFloat(data.fipe || 0) - parseFloat(data.valor_compra || 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            dias_no_estoque: calculateDaysInStock(data.data_compra, data.data_venda),
          });
        }
      } catch (error) {
        console.error('Erro ao buscar veículo', error);
      }
    };
    fetchVehicle();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehicle((prev) => ({ ...prev, [name]: value }));

    if (name === 'fipe' || name === 'valor_compra') {
      const fipeValue = parseFloat(name === 'fipe' ? value : vehicle.fipe) || 0;
      const valorCompra = parseFloat(name === 'valor_compra' ? value : vehicle.valor_compra) || 0;
      const margemFipe = (fipeValue - valorCompra).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      setVehicle((prev) => ({ ...prev, margem_fipe: margemFipe }));
    }

    if (name === 'data_venda' && vehicle.data_compra) {
      const dateVenda = new Date(value);
      const dateCompra = new Date(vehicle.data_compra);
      const diffDays = calculateDaysInStock(vehicle.data_compra, value);
      setVehicle((prev) => ({ ...prev, dias_no_estoque: diffDays }));
    }
  };

  const calculateDaysInStock = (dataCompra, dataVenda) => {
    if (dataCompra && dataVenda) {
      const dateCompra = new Date(dataCompra);
      const dateVenda = new Date(dataVenda);
      const diffTime = dateVenda - dateCompra;
      return diffTime > 0 ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : 0;
    }
    return '';
  };

  const handleFileChange = (e, type) => {
    if (type === 'crlv') {
      setCrlvFile(e.target.files[0]);
    } else if (type === 'dut') {
      setDutFile(e.target.files[0]);
    }
  };

  const calculateTotalExpenses = () => {
    const despesas = [
      parseFloat(vehicle.despesas_transferencia.replace(',', '.') || 0),
      parseFloat(vehicle.comissao.replace(',', '.') || 0),
      parseFloat(vehicle.ipva.replace(',', '.') || 0),
      parseFloat(vehicle.frete.replace(',', '.') || 0),
    ];
    return despesas.reduce((acc, despesa) => acc + despesa, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const calculateProfit = () => {
    const valorVenda = parseFloat(vehicle.valor_venda.replace(',', '.') || 0);
    const valorCompra = parseFloat(vehicle.valor_compra.replace(',', '.') || 0);
    const totalDespesas = parseFloat(vehicle.despesas_transferencia || 0) +
                          parseFloat(vehicle.comissao || 0) +
                          parseFloat(vehicle.ipva || 0) +
                          parseFloat(vehicle.frete || 0);

    return (valorVenda - totalDespesas - valorCompra).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (vehicle.status === 'Vendido' && (!vehicle.valor_venda || !vehicle.data_venda)) {
      alert('Por favor, insira o Valor de Venda e a Data da Venda.');
      return;
    }

    const token = localStorage.getItem('token');
    try {
      await axios.put(`https://api.velhochicoveiculos.com.br/api/vehicles/update/${id}`, vehicle, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Veículo atualizado com sucesso!');
      navigate('/vehicles');
    } catch (error) {
      console.error('Erro ao atualizar veículo', error);
      alert('Erro ao atualizar veículo');
    }
  };

  const renderYearOptions = () => {
    const years = [];
    for (let year = 1980; year <= currentYear; year++) {
      years.push(<option key={year} value={year}>{year}</option>);
    }
    return years;
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Editar Veículo</h2>
      <form onSubmit={handleSubmit} className="card p-4">
        <div className="row">
          {[{ name: 'placa', label: 'Placa' }, { name: 'fabricante', label: 'Fabricante' }, { name: 'modelo', label: 'Modelo' }, { name: 'cor', label: 'Cor' }, { name: 'quilometragem', label: 'Quilometragem' }, { name: 'origem_compra', label: 'Origem Compra' }, { name: 'data_compra', label: 'Data Compra', type: 'date' }, { name: 'valor_pedido', label: 'Valor Pedido' }, { name: 'valor_compra', label: 'Valor Compra' }, { name: 'fipe', label: 'FIPE' }]
            .map((field, index) => (
              <div key={index} className="col-md-6 mb-3">
                <label>{field.label}</label>
                <input
                  type={field.type || 'text'}
                  name={field.name}
                  className="form-control"
                  placeholder={field.label}
                  value={vehicle[field.name] || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            ))}
          <div className="col-md-6 mb-3">
            <label>Ano Fabricação</label>
            <select name="ano_fab" className="form-control" value={vehicle.ano_fab} onChange={handleChange} required>
              <option value="">Selecione o ano</option>
              {renderYearOptions()}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label>Ano Modelo</label>
            <select name="ano_mod" className="form-control" value={vehicle.ano_mod} onChange={handleChange} required>
              <option value="">Selecione o ano</option>
              {renderYearOptions()}
            </select>
          </div>
          <div className="col-md-12">
            <p><strong>Margem FIPE:</strong> {vehicle.margem_fipe}</p>
          </div>
        </div>

        <div className="form-group">
          <h5>Despesas</h5>
          {[{ name: 'despesas_transferencia', label: 'Despesas de Transferência' }, { name: 'comissao', label: 'Comissão' }, { name: 'ipva', label: 'IPVA' }, { name: 'frete', label: 'Frete' }]
            .map((field, index) => (
              <div key={index} className="col-md-6 mb-3">
                <label>{field.label}</label>
                <input
                  type="number"
                  name={field.name}
                  className="form-control"
                  placeholder={field.label}
                  value={vehicle[field.name] || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            ))}
          <p><strong>Total de Despesas:</strong> {calculateTotalExpenses()}</p>
        </div>

        <div className="form-group">
          <label>Status</label>
          <select name="status" className="form-select" value={vehicle.status} onChange={handleChange} required>
            <option value="Estoque">Estoque</option>
            <option value="Vendido">Vendido</option>
            <option value="Negociação">Negociação</option>
            <option value="Chegando">Chegando</option>
          </select>
        </div>

        {vehicle.status === 'Vendido' && (
          <>
            <div className="form-group mt-3">
              <label>Valor de Venda</label>
              <input
                type="number"
                name="valor_venda"
                className="form-control"
                placeholder="Valor de Venda"
                value={vehicle.valor_venda || ''}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label>Data da Venda</label>
              <input
                type="date"
                name="data_venda"
                className="form-control"
                value={vehicle.data_venda || ''}
                onChange={handleChange}
                required
              />
            </div>

            <p><strong>Dias no Estoque:</strong> {vehicle.dias_no_estoque}</p>

            <div className="form-group mt-3">
              <label>Comprador</label>
              <input
                type="text"
                name="comprador"
                className="form-control"
                placeholder="Comprador"
                value={vehicle.comprador || ''}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label>Número Comprador</label>
              <input
                type="text"
                name="numero_comprador"
                className="form-control"
                placeholder="Número Comprador"
                value={vehicle.numero_comprador || ''}
                onChange={handleChange}
                required
              />
            </div>
          </>
        )}

        <div className="form-group mt-4">
          <h5>Documentos</h5>
          <label>
            Upload CRLV:
            <input type="file" className="form-control" accept=".pdf" onChange={(e) => handleFileChange(e, 'crlv')} />
          </label>
          <label>
            Upload DUT:
            <input type="file" className="form-control" accept=".pdf" onChange={(e) => handleFileChange(e, 'dut')} />
          </label>
        </div>

        <button type="submit" className="btn btn-primary mt-3">Atualizar Veículo</button>
      </form>
    </div>
  );
};

export default EditVehicle;
