import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';

const LeadForm = ({ onAddLead }) => {
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [classificacao, setClassificacao] = useState('');
  const [origem, setOrigem] = useState('Instagram');
  const [nota, setNota] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        'https://api.velhochicoveiculos.com.br/api/leads/add',
        { nome, telefone, classificacao, origem, nota },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setError(null);
      alert('Lead cadastrado com sucesso!');

      setNome('');
      setTelefone('');
      setClassificacao('');
      setOrigem('Instagram');
      setNota('');

      if (onAddLead) {
        onAddLead(response.data);
      }
    } catch (error) {
      console.error('Erro ao cadastrar lead:', error);
      setError('Erro ao cadastrar lead');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Cadastrar Lead
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Nome"
          fullWidth
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
          margin="normal"
        />
        <TextField
          label="Telefone"
          fullWidth
          value={telefone}
          onChange={(e) => setTelefone(e.target.value)}
          required
          margin="normal"
        />
        
        <FormControl fullWidth margin="normal">
          <InputLabel>Classificação</InputLabel>
          <Select
            value={classificacao}
            onChange={(e) => setClassificacao(e.target.value)}
            required
          >
            <MenuItem value="">
              <em>Selecione uma classificação</em>
            </MenuItem>
            <MenuItem value="Lead Quente">Lead Quente</MenuItem>
            <MenuItem value="Lead Frio">Lead Frio</MenuItem>
            <MenuItem value="Cliente Interessado">Cliente Interessado</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Origem</InputLabel>
          <Select
            value={origem}
            onChange={(e) => setOrigem(e.target.value)}
            required
          >
            <MenuItem value="Instagram">Instagram</MenuItem>
            <MenuItem value="Facebook">Facebook</MenuItem>
            <MenuItem value="Olx">OLX</MenuItem>
            <MenuItem value="Webmotors">Webmotors</MenuItem>
            <MenuItem value="Presencial">Presencial</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Nota"
          fullWidth
          value={nota}
          onChange={(e) => setNota(e.target.value)}
          multiline
          rows={4}
          margin="normal"
        />

        <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
          Cadastrar Lead
        </Button>
      </Box>
      {error && (
        <Typography color="error" align="center" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default LeadForm;
