import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
} from '@mui/material';

const AddVehicle = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const [vehicle, setVehicle] = useState({
    placa: '',
    fabricante: '',
    modelo: '',
    cor: '',
    ano_fab: '',
    ano_mod: '',
    quilometragem: '',
    origem_compra: '',
    comprador: '',
    numero_comprador: '',
    data_compra: '',
    data_venda: '',
    valor_pedido: '',
    valor_compra: '',
    fipe: '',
    despesas_transferencia: '',
    comissao: '',
    ipva: '',
    frete: '',
    status: 'Estoque',
    valor_venda: '',
    dias_no_estoque: '',
    margem_fipe: '',
  });

  const [crlvFile, setCrlvFile] = useState(null);
  const [dutFile, setDutFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehicle((prev) => ({ ...prev, [name]: value }));

    if (name === 'fipe' || name === 'valor_compra') {
      const fipeValue = parseFloat(name === 'fipe' ? value : vehicle.fipe) || 0;
      const valorCompra = parseFloat(name === 'valor_compra' ? value : vehicle.valor_compra) || 0;
      const margemFipe = (fipeValue - valorCompra).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      setVehicle((prev) => ({ ...prev, margem_fipe: margemFipe }));
    }

    if (name === 'data_venda' && vehicle.data_compra) {
      const dateVenda = new Date(value);
      const dateCompra = new Date(vehicle.data_compra);
      const diffTime = dateVenda - dateCompra;
      const diffDays = diffTime > 0 ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : 0;
      setVehicle((prev) => ({ ...prev, dias_no_estoque: diffDays }));
    }
  };

  const handleFileChange = (e, type) => {
    if (type === 'crlv') {
      setCrlvFile(e.target.files[0]);
    } else if (type === 'dut') {
      setDutFile(e.target.files[0]);
    }
  };

  const calculateTotalExpenses = () => {
    const despesas = [
      parseFloat(vehicle.despesas_transferencia.replace(',', '.') || 0),
      parseFloat(vehicle.comissao.replace(',', '.') || 0),
      parseFloat(vehicle.ipva.replace(',', '.') || 0),
      parseFloat(vehicle.frete.replace(',', '.') || 0),
    ];
    return despesas.reduce((acc, despesa) => acc + despesa, 0).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const uploadFile = async (file, fileType) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('fileType', fileType);

    try {
      await axios.post(`https://api.velhochicoveiculos.com.br/api/vehicles/upload/temp`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error(`Erro ao enviar ${fileType}:`, error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (vehicle.status === 'Vendido' && (!vehicle.valor_venda || !vehicle.data_venda)) {
      alert('Por favor, insira o Valor de Venda e a Data da Venda.');
      return;
    }

    const token = localStorage.getItem('token');
    try {
      await axios.post('https://api.velhochicoveiculos.com.br/api/vehicles/add', vehicle, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Veículo adicionado com sucesso!');

      if (crlvFile) await uploadFile(crlvFile, 'pdf_crlv');
      if (dutFile) await uploadFile(dutFile, 'pdf_dut');

      navigate('/vehicles');
    } catch (error) {
      console.error('Erro ao adicionar veículo', error);
      alert('Erro ao adicionar veículo');
    }
  };

  const renderYearOptions = () => {
    const years = [];
    for (let year = 1980; year <= currentYear; year++) {
      years.push(
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      );
    }
    return years;
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Adicionar Veículo
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          {[
            { name: 'placa', label: 'Placa' },
            { name: 'fabricante', label: 'Fabricante' },
            { name: 'modelo', label: 'Modelo' },
            { name: 'cor', label: 'Cor' },
            { name: 'quilometragem', label: 'Quilometragem' },
            { name: 'origem_compra', label: 'Origem Compra' },
            { name: 'data_compra', label: 'Data Compra', type: 'date' },
            { name: 'valor_pedido', label: 'Valor Pedido' },
            { name: 'valor_compra', label: 'Valor Compra' },
            { name: 'fipe', label: 'FIPE' },
          ].map((field, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <TextField
                fullWidth
                type={field.type || 'text'}
                name={field.name}
                label={field.label}
                value={vehicle[field.name] || ''}
                onChange={handleChange}
                required
              />
            </Grid>
          ))}

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Ano Fabricação</InputLabel>
              <Select name="ano_fab" value={vehicle.ano_fab} onChange={handleChange}>
                {renderYearOptions()}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Ano Modelo</InputLabel>
              <Select name="ano_mod" value={vehicle.ano_mod} onChange={handleChange}>
                {renderYearOptions()}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              name="despesas_transferencia"
              label="Despesas de Transferência"
              value={vehicle.despesas_transferencia || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              name="comissao"
              label="Comissão"
              value={vehicle.comissao || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              name="ipva"
              label="IPVA"
              value={vehicle.ipva || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              name="frete"
              label="Frete"
              value={vehicle.frete || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Total de Despesas: {calculateTotalExpenses()}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select name="status" value={vehicle.status} onChange={handleChange}>
                <MenuItem value="Estoque">Estoque</MenuItem>
                <MenuItem value="Vendido">Vendido</MenuItem>
                <MenuItem value="Negociação">Negociação</MenuItem>
                <MenuItem value="Chegando">Chegando</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {vehicle.status === 'Vendido' && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="valor_venda"
                  label="Valor de Venda"
                  value={vehicle.valor_venda || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  name="data_venda"
                  label="Data da Venda"
                  value={vehicle.data_venda || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Dias no Estoque: {vehicle.dias_no_estoque}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="comprador"
                  label="Comprador"
                  value={vehicle.comprador || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="numero_comprador"
                  label="Número Comprador"
                  value={vehicle.numero_comprador || ''}
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Typography variant="h6" sx={{ mt: 3 }}>
          Margem FIPE: {vehicle.margem_fipe}
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Button variant="contained" component="label">
            Upload CRLV
            <input hidden accept=".pdf" type="file" onChange={(e) => handleFileChange(e, 'crlv')} />
          </Button>
          <Button variant="contained" component="label" sx={{ ml: 2 }}>
            Upload DUT
            <input hidden accept=".pdf" type="file" onChange={(e) => handleFileChange(e, 'dut')} />
          </Button>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Button variant="contained" color="primary" type="submit">
            Adicionar Veículo
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddVehicle;
